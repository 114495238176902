<template>
  <div class="language-drop">
    <div class="language-drop-items" v-for="(lang,index) in languages" :key="index">
      <p @click="sendLanguage(lang.language)">{{lang.language}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["languages"],
  data() {
    return {};
  },
  methods: {
    sendLanguage(data) {
      this.$emit("languageItem", data);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.language-drop {
  position: absolute;
  background-color: $clr-dark-gd2;
  border-radius: 10px;
  max-height: 180px;
  overflow-y: auto;
  &-items {
    padding: 0.5rem;
    font-family: $font-regular;
    p {
      color: $clr-light-gd4;
    }
  }
}
</style>